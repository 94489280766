import * as React from "react";
import { Button } from "@clear-treasury/design-system";

import Utils from "../../core/Utils";
import { useMutation } from "../../hooks/useMutation";
import QuoteCountdown, {
  REQUOTE_INTERVAL_SECS,
} from "../QuoteCountdown/QuoteCountdown";
import { DebitSource } from "../../pages/transfer";
import { BookTradeDocument } from "../../graphql/gql-types";
import { QuoteWithClientRef } from "./QuoteForm";
import { formatValueDate } from "./lib/valueDate";

import type {
  BookTradeMutationVariables,
  BookTradeResult,
  Trade,
} from "../../graphql/gql-types";

export interface IAmountConfirmation {
  quote: QuoteWithClientRef;
  quoting: boolean;
  quotingResponseTime: null | number;

  onConfirmAmount: (data: Trade) => void;
  onBookTradeError: (error: unknown) => void;
  onCancelConfirm: (event: React.FormEvent) => void;
  onQuoteCountdownComplete: () => void;

  debitSource?: DebitSource;
  trade?: Omit<Trade, "id">;
}

// TODO Replace the UI with the design system's list
const AmountConfirmation: React.FC<IAmountConfirmation> = ({
  quote,
  quoting,
  quotingResponseTime,
  onConfirmAmount,
  onCancelConfirm,
  onBookTradeError,
  onQuoteCountdownComplete,
  trade,
  debitSource,
}): JSX.Element => {
  const isSameCurrency = quote.currency_buy === quote.currency_sell;
  const [bookTrade, setBookTrade] = React.useState<boolean>(false);

  // Cache value date to avoid rendering errors if it's undefined between quotes
  let value_date = "";
  if (quote.value_date) {
    value_date = quote.value_date;
  }

  const { isLoading: bookingTrade, error: tradeError } = useMutation<
    BookTradeResult,
    BookTradeMutationVariables["input"]
  >(
    bookTrade && !isSameCurrency ? BookTradeDocument : null,
    {
      quote_id: quote?.ID ?? quote.ID,
      client_ref: quote.client_ref,
      source_of_funds:
        debitSource === DebitSource.Balance ? "on account" : null,
    },
    (data: BookTradeResult) => {
      onConfirmAmount(data.trade as Trade);
    },
    (error: unknown) => onBookTradeError(error)
  );

  return (
    <div>
      <h2 className="formatValueDate text-teal-700 text-2xl mb-2">
        Confirm and book
      </h2>
      <p className="text-lg text-gray-600 mb-14">
        By confirming your quote below you are entering into a legal contract to
        trade as per our terms and conditions.
      </p>

      <div className="flex justify-between mb-4 border-b border-gray-200 pb-4">
        <h3 className="text-xl text-gray-700">Your trade</h3>
      </div>

      <div className="flex justify-between mb-4">
        <span className="text-lg text-teal-700">I have (sell)</span>
        <span data-testid="you-send-amount" className="text-lg text-gray-600">
          {`${
            trade?.sold_amount
              ? `${Utils.parseAmountByCurrencyCode(
                  trade.sold_amount,
                  trade.sold_currency
                )} ${trade.sold_currency}`
              : "Loading..."
          }`}
        </span>
      </div>

      <div className="flex justify-between mb-4">
        <span className="text-lg text-teal-700">I want (buy)</span>
        <span
          data-testid="you-receive-amount"
          className="text-lg text-gray-600"
        >
          {`${
            trade?.bought_amount
              ? `${Utils.parseAmountByCurrencyCode(
                  trade.bought_amount,
                  trade.bought_currency
                )} ${trade.bought_currency}`
              : "Loading..."
          }`}
        </span>
      </div>

      <div className="flex justify-between mb-5">
        <div>
          <span className="text-lg text-teal-700 block">Exchange rate</span>

          {trade && (
            <span className="text-xs text-gray-400 block">
              The rate quoted is a live rate, valid for {REQUOTE_INTERVAL_SECS}{" "}
              seconds.
            </span>
          )}
        </div>

        <div className="flex space-x-4">
          {!quote?.quote_rate && (
            <div className="flex flex-col items-end">
              <span
                data-testid="you-receive-amount"
                className="text-lg text-gray-600"
              >
                Loading...
              </span>
            </div>
          )}
          {trade && trade.sold_currency !== trade.bought_currency && (
            <div className="flex justify-center items-center">
              <QuoteCountdown
                _key={
                  !quoting && `${quote?.ID}_${quoting}_${quotingResponseTime}`
                }
                timestamp={quotingResponseTime}
                isPlaying={!quoting && !!quote?.quote_rate && !bookingTrade}
                onComplete={onQuoteCountdownComplete}
              />
            </div>
          )}

          {trade && quote?.quote_rate && (
            <div className="flex flex-col items-end">
              <div className="text-lg text-gray-600">
                {quote.quote_rate.toFixed(4)}
              </div>
              <div className="text-xs text-gray-400">
                {`(${(1 / quote.quote_rate).toFixed(4)})`}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between mb-14">
        <span className="text-lg text-teal-700">Value Date</span>
        <span data-testid="value-date" className="text-lg text-gray-600">
          {formatValueDate(value_date)}
        </span>
      </div>

      <div className="flex justify-center">
        <Button
          onClick={onCancelConfirm}
          className="border-2 border-gray-700 rounded py-2.5 px-6 mr-6"
        >
          Cancel
        </Button>
        <Button
          emphasis={quote?.quote_rate ? "primary" : "secondary"}
          disabled={!quote?.quote_rate || tradeError || quoting}
          onClick={() => setBookTrade(true)}
          loading={bookingTrade || quoting}
          size={Button.Size.LARGE}
        >
          Confirm and book
        </Button>
      </div>
    </div>
  );
};

export default AmountConfirmation;
