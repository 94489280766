import React from "react";
import { CalendarIcon, XCircleIcon } from "@heroicons/react/outline";
import { Input } from "@clear-treasury/design-system";

import type {
  InputProps,
  InputRef,
} from "@clear-treasury/design-system/dist/components/input/Input";

export interface DateInputProps extends InputProps {
  onClear: () => void;
}

const DateInput = React.forwardRef<InputRef, DateInputProps>((props, ref) => {
  const { onClear, ...inputProps } = props;

  let iconClasses = "datepicker-input-icon h-5 w-5 absolute right-3";
  if (props.label) {
    iconClasses += " top-1/2";
  } else {
    iconClasses += " top-1/4";
  }

  const name = inputProps.label
    ? `date-picker-input-${inputProps.label.replace(/\s/g, "-")}`
    : "date-picker-input";

  return (
    <div className="relative">
      <Input
        {...inputProps}
        ref={ref}
        readOnly
        name={name}
        className="text-gray-400"
        placeholder="DD-MM-YYYY"
      />
      <div className={iconClasses}>
        {props.value ? (
          <div className="cursor-pointer pt-px" onClick={onClear}>
            <XCircleIcon />
          </div>
        ) : (
          <CalendarIcon />
        )}
      </div>
    </div>
  );
});

DateInput.displayName = "DateInput";

export default DateInput;
