import {
  addBusinessDays,
  addDays,
  isAfter,
  isSameDay,
  isWeekend,
  subDays,
} from "date-fns";
import React, { useEffect, useState } from "react";
import RDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { InputRef } from "@clear-treasury/design-system/dist/components/input/Input";
import DateHeader from "./DateHeader";
import DateInput from "./DateInput";
import Utils from "../../core/Utils";

export interface DatePickerProps extends ReactDatePickerProps {
  label?: string;
  limitOfAvailableDays?: number;
  bankHolidays?: Date[];
  isNonEEACorporateClient: boolean;
}

export const addBusinessDaysWithBankHolidays = (
  date: Date,
  days: number,
  bankHolidays: Date[]
) => {
  let daysToAdd = days;
  let currentDate = date;
  while (daysToAdd > 0) {
    currentDate = addBusinessDays(currentDate, 1);
    if (
      !bankHolidays.some((bankHoliday) => isSameDay(currentDate, bankHoliday))
    ) {
      daysToAdd--;
    }
  }
  return currentDate;
};

const getPrivateClientRangeOfAvailableDays = (
  date: Date,
  limitOfAvailableDays: number,
  bankHolidays: Date[]
) => {
  return [
    {
      start: subDays(date, 1),
      end: addBusinessDaysWithBankHolidays(
        date,
        limitOfAvailableDays,
        bankHolidays
      ),
    },
  ];
};

export const getCorporateClientRangeOfAvailableDays = (
  date: Date,
  limitOfAvailableDays: number,
  bankHolidays: Date[]
) => {
  let endDate = addDays(date, limitOfAvailableDays);

  while (
    !isWeekday(endDate) ||
    bankHolidays.some((bankHoliday) => isSameDay(endDate, bankHoliday))
  ) {
    endDate = addDays(endDate, 1);
  }

  return [
    {
      start: subDays(date, 1),
      end: endDate,
    },
  ];
};

const isWeekday = (date: Date) => {
  return !isWeekend(date);
};

const isSameDayOrAfter = (target: Date, date: Date) =>
  isSameDay(date, target) || isAfter(date, target);

const DatePicker = React.forwardRef<InputRef, DatePickerProps>((props, ref) => {
  const { label, onChange, limitOfAvailableDays, ...datePickerProps } = props;

  datePickerProps.minDate = datePickerProps.minDate || new Date();

  const [rangeOfAvailableDays, setRangeOfAvailableDays] = useState<
    Array<{ start: Date; end: Date }> | undefined
  >();

  useEffect(() => {
    if (Utils.isNotEmpty(limitOfAvailableDays)) {
      props.isNonEEACorporateClient &&
      process.env.NEXT_PUBLIC_ENABLE_TRADE_FORWARDS
        ? setRangeOfAvailableDays(
            getCorporateClientRangeOfAvailableDays(
              props.minDate,
              limitOfAvailableDays,
              props.bankHolidays || []
            )
          )
        : setRangeOfAvailableDays(
            getPrivateClientRangeOfAvailableDays(
              props.minDate,
              limitOfAvailableDays,
              props.bankHolidays || []
            )
          );
    }
  }, [limitOfAvailableDays]);

  return (
    <RDatePicker
      selected={props.selected}
      excludeDates={props.bankHolidays || []}
      includeDateIntervals={rangeOfAvailableDays}
      calendarStartDay={1}
      onChange={onChange}
      filterDate={isWeekday}
      dateFormat="dd-MM-yyyy"
      customInput={
        <DateInput
          ref={ref}
          label={label}
          name="date-picker-input"
          onClear={() => props.onChange(null, undefined)}
        />
      }
      renderCustomHeader={DateHeader}
      showDisabledMonthNavigation
      disabledKeyboardNavigation
      renderDayContents={(day) => {
        return (
          <div className="w-full h-full flex justify-center items-center">
            {day}
          </div>
        );
      }}
      dayClassName={(date) => {
        let classes = "m-0 text-sm h-8 w-8";
        if (isSameDayOrAfter(props.minDate, date)) {
          classes += " hover:bg-teal-200";
        }
        if (!!props.selected && isSameDay(date, props.selected)) {
          classes += " bg-green-600";
        } else if (isSameDay(props.minDate, date)) {
          classes += " bg-teal-100 text-teal-400 font-normal";
        }
        return classes;
      }}
      clearButtonClassName="m-3"
      {...datePickerProps}
    />
  );
});

DatePicker.displayName = "DatePicker";

export default DatePicker;
