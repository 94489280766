import React from "react";
import { format } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

export interface DateHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}

export type IDateHeader = React.FC<DateHeaderProps>;

const DateHeader: IDateHeader = ({ date, decreaseMonth, increaseMonth }) => {
  return (
    <div className="flex justify-between items-center px-3 py-2">
      <div
        className="h-3 w-3 cursor-pointer nav-button"
        onClick={decreaseMonth}
      >
        <ChevronLeftIcon />
      </div>
      <div className="current-date">{format(date, "MMMM yyyy")}</div>
      <div
        className="h-3 w-3 cursor-pointer nav-button"
        onClick={increaseMonth}
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
};

export default DateHeader;
