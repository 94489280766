import * as React from "react";
import { TabState } from "./Tab";
import Surface from "../surface/Surface";
import Utils from "../../core/Utils";

type StepItemProps = {
  stepTitle?: string;
  form?: React.ReactElement;
  children?: React.ReactElement;
};

type StepsProps = {
  nav?: React.ReactElement;
  children?: React.ReactElement[];
  goToStep?: number;
  onComplete?(): void;
  initialStepIndex?: number;
};

const Step = ({ form, children, ...props }: StepItemProps): JSX.Element => {
  return React.cloneElement(children || form, { ...props });
};

const Steps = ({
  nav,
  children,
  goToStep,
  onComplete,
  initialStepIndex,
}: StepsProps): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(initialStepIndex || 0);
  const [formState, setFormState] = React.useState({
    [0]: TabState.COMPLETE,
  });

  React.useEffect(() => {
    if (goToStep != null) setActiveStep(goToStep);
  }, [goToStep]);

  const Nav = (): JSX.Element | null =>
    nav ? (
      <div className="sm:flex justify-center sm:mx-4">
        {children.map((child, index) => {
          if (Utils.isEmpty(child)) return <div key={index + child.props} />;

          return React.cloneElement(nav, {
            key: index,
            step: index,
            title: child?.props.stepTitle,
            state:
              activeStep === index
                ? TabState.ACTIVE
                : formState[index] || TabState.DEFAULT,
            isEnabled: false,
            onClick: () => setActiveStep(index),
          });
        })}
      </div>
    ) : null;

  return (
    <>
      <Nav />

      <Surface align={Surface.Align.CENTER}>
        {children.map((child, index) => {
          if (Utils.isEmpty(child)) return <div key={index + child.props} />;
          return (
            index === activeStep &&
            React.cloneElement(child, {
              key: index,
              onComplete: (data) => {
                child.props.form?.props.onComplete &&
                  child.props.form.props.onComplete(data);

                if (activeStep === children.length - 1) {
                  onComplete();
                } else {
                  setFormState(() => ({
                    ...formState,
                    [activeStep]: TabState.COMPLETE,
                  }));
                  setActiveStep((activeStep) => activeStep + 1);
                }
              },
            })
          );
        })}
      </Surface>
    </>
  );
};

Steps.Step = Step;

export default Steps;
