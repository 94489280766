import React from "react";
import { FormProps } from "./types";
import * as yup from "yup";
import { Input } from "@clear-treasury/design-system";

export const GbpGbSchema = yup.object({
  currency: yup.string().required("Currency is required"),
  country_code: yup.string().required("Country is required"),
  sort_code: yup
    .string()
    .matches(/^[0-9]{6}$/, "Must be 6 digits exactly")
    .required("Sort code is required"),
  bank_name: yup.string().optional(),
  account_name: yup.string().required("Account name is required"),
  account_number: yup
    .string()
    .required("Account number is required")
    .matches(/^[0-9]{8}$/, "Must be 8 digits exactly"),
  alias: yup.string().optional(),
  email: yup.string().email("Must be a valid email address").optional(),
});

export function GbpGbForm({ handleChange, errors }: FormProps): JSX.Element {
  return (
    <>
      <div className="block w-full mb-8">
        <h2 className="text-theme-color-on-surface text-2xl">
          Account details
        </h2>

        <p className="text-l text-gray-500">
          Please provide the details of the beneficiary acocunt
        </p>
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="sort_code"
          label="Sort Code"
          placeholder="Enter a valid UK sort code"
          onChange={handleChange}
          hint="Must be 6 digits exactly"
          errors={errors}
        />
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="bank_name"
          label="Bank name (optional)"
          placeholder="Enter the bank or payment service provider name"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="account_name"
          label="Account name"
          placeholder="Enter the beneficiary's account name"
          hint="This should match the name registered on the account you want to pay"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="account_number"
          label="Account number"
          placeholder="Enter the beneficiary's account number"
          hint="Must be 8 digits exactly"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="block w-full mb-8">
        <h2 className="text-theme-color-on-surface text-2xl">
          Additional information
        </h2>
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="alias"
          label="Beneficiary alias (optional)"
          placeholder="Enter a nickname for the beneficiary"
          hint="This is a reference that we will use to display the beneficiary for you"
          onChange={handleChange}
          errors={errors}
        />
      </div>
      <div className="mb-8">
        <Input
          type="text"
          name="email"
          label="Email address (optional)"
          placeholder="Enter an email address for the beneficiary"
          onChange={handleChange}
          errors={errors}
        />
      </div>
    </>
  );
}
