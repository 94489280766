import * as React from "react";
import { Button } from "@clear-treasury/design-system";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import { Quote } from "../../../graphql/gql-types";
import { REQUOTE_INTERVAL_SECS } from "../../QuoteCountdown/QuoteCountdown";

export interface IFooterProps {
  quote: Quote;
  quoting: boolean;
  quoteError: Record<string, any>;
  allowedToTrade: boolean;
  disabled?: boolean;
}

const Footer: React.FC<IFooterProps> = ({
  quote,
  quoting,
  quoteError,
  disabled,
  allowedToTrade,
}) => {
  const router = useRouter();

  const { beneficiary, balance_currency } = router.query;
  return (
    <>
      <div className="flex justify-between space-x-4 border-t border-gray-200 py-6">
        <span className="text-sm text-gray-500">
          The rate quoted is a live rate, valid for {REQUOTE_INTERVAL_SECS}{" "}
          seconds.{" "}
          {allowedToTrade
            ? "You will be asked to accept a confirmed rate as part of the transfer process."
            : "You don’t have the required permission to book a trade."}
        </span>
        <Button
          emphasis={
            quote?.quote_rate && allowedToTrade ? "primary" : "secondary"
          }
          disabled={
            disabled ||
            !quote?.quote_rate ||
            quoteError?.errors > 0 ||
            quoting ||
            !allowedToTrade
          }
          loading={quoting}
          size={Button.Size.LARGE}
        >
          Continue
        </Button>
      </div>
      {(beneficiary || balance_currency) && (
        <div className="flex justify-between">
          <Button
            size={Button.Size.LARGE}
            emphasis={Button.Emphasis.TRANSPARENT}
            onClick={(ev) => {
              ev.preventDefault();
              router.back();
            }}
          >
            <ArrowLeftIcon width="16" />
            Back
          </Button>
        </div>
      )}
    </>
  );
};

export default Footer;
