import { GbpGbSchema, GbpGbForm } from "./GBP_GB.form";
import { DefaultSchema, DefaultForm } from "./Default.form";
import { EurEeaSchema, EurEeaForm } from "./EUR_EEA.form";
import { UsdUsSchema, UsdUsForm } from "./USD_US.form";

import {
  Form as ExtendedBeneForm,
  Schema as ExtendedBeneShema,
} from "./ExtendedBeneInfo.form";

export default {
  "GBP/GB": {
    schema: GbpGbSchema,
    form: GbpGbForm,
  },
  "EUR/EEA": {
    schema: EurEeaSchema,
    form: EurEeaForm,
  },
  "USD/US": {
    schema: UsdUsSchema,
    form: UsdUsForm,
  },

  AU: {
    schema: ExtendedBeneShema,
    form: ExtendedBeneForm,
  },
  CA: {
    schema: ExtendedBeneShema,
    form: ExtendedBeneForm,
  },
  IN: {
    schema: ExtendedBeneShema,
    form: ExtendedBeneForm,
  },
  PK: {
    schema: ExtendedBeneShema,
    form: ExtendedBeneForm,
  },
  PL: {
    schema: ExtendedBeneShema,
    form: ExtendedBeneForm,
  },
  CN: {
    schema: ExtendedBeneShema,
    form: ExtendedBeneForm,
  },

  Default: {
    schema: DefaultSchema,
    form: DefaultForm,
  },
};
