import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { CalendarIcon } from "@heroicons/react/outline";

import Utils from "../../core/Utils";
import useQuery from "../../hooks/useQuery";
import { useApp } from "../../ctx/AppProvider";
import { useToast, TOAST_STATUSES } from "../../ctx/ToastProvider";
import { Currency, GetQuoteDocument } from "../../graphql/gql-types";
import { DebitSource } from "../../pages/transfer";
import Alert from "../Alert/Alert";
import BalanceLabel from "../BalanceLabel/BalanceLabel";
import QuoteCountdown from "../QuoteCountdown/QuoteCountdown";
import DatePicker from "../datepicker/DatePicker";
import MoneyInput from "../money-input/MoneyInput";
import * as valueDate from "./lib/valueDate";
import Footer from "./ui/Footer";
import AmountConfirmation from "./AmountConfirmation";

import type {
  Balance,
  Beneficiary,
  Client,
  GetQuoteQuery,
  GetQuoteQueryVariables,
  Quote,
  Trade,
} from "../../graphql/gql-types";
import type { PartialBy } from "../../lib/UtilityTypes";

import messages from "../../data/i18n/en.json";
import { logWebActivity } from "../../lib/logger";

type PartialBalance = PartialBy<Balance, "funds_held" | "uninstructed">;

export interface QuoteWithClientRef extends Quote {
  client_ref: string;
}

export interface QuoteFormProps {
  client?: Client;
  previousQuote?: QuoteWithClientRef;
  onComplete?: (object: { quote: QuoteWithClientRef; trade?: Trade }) => void;
  debitSource?: DebitSource;
  selectedBalance?: PartialBalance;
  isNewTrade?: boolean;
  onTradeSelect?: (item: Trade) => void;
  beneficiary?: Beneficiary;
  currencies?: Currency[];
}

export const defaultTransferValues = {
  sell: { amount: 1000.0, currency: "GBP" },
  buy: { currency: "EUR" },
};

export enum AMOUNT_TYPES {
  SELL = "SELL",
  BUY = "BUY",
}

// TODO Remove it! It is used in more ona places
// @see: src/components/FastQuoteForm/FastQuoteForm.tsx:25

const getInitialSourceAmount = (
  debitSource: DebitSource,
  selectedBalance: PartialBalance,
  previousQuote: QuoteWithClientRef
) => {
  if (previousQuote?.buy_amount) {
    return undefined;
  }
  if (previousQuote?.sell_amount) {
    return previousQuote?.sell_amount?.toFixed(2);
  }

  return debitSource === DebitSource.Balance
    ? selectedBalance.balance?.toFixed(2)
    : defaultTransferValues.sell.amount?.toFixed(2);
};

const getInitialSourceCurrency = (
  debitSource: DebitSource,
  selectedBalance: PartialBalance,
  previousQuote: QuoteWithClientRef
) => {
  if (previousQuote?.currency_sell) {
    return previousQuote?.currency_sell;
  }

  return debitSource === DebitSource.Balance
    ? selectedBalance.currency
    : defaultTransferValues.sell.currency;
};

const getQuoteLimit = (currency: string, currencies: Currency[]): number => {
  return currencies.find((c) => c.currency_code === currency).max_trade_size;
};

const getCuttOffTime = (currency: string, currencies: Currency[]): string => {
  return currencies.find((c) => c.currency_code === currency).cut_off_time;
};

const QuoteForm = ({
  previousQuote,
  onComplete,
  debitSource,
  selectedBalance,
  isNewTrade,
  beneficiary,
  currencies,
}: QuoteFormProps): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const toast = useToast();
  const querySourceCurrency = query?.sourceCurrency as string;
  const queryTargetCurrency = query?.targetCurrency as string;
  const queryAmount = query?.amountValue as string;
  const queryType = query?.amountType as AMOUNT_TYPES;
  const [quoteCounter, setQuoteCounter] = React.useState<number>(0);
  const [bankHolidays, setBankHolidays] = React.useState<Date[]>([]);

  const [amount, setAmount] = React.useState({
    sourceAmount:
      queryAmount && queryType && queryType === AMOUNT_TYPES.SELL
        ? queryAmount
        : getInitialSourceAmount(debitSource, selectedBalance, previousQuote),
    targetAmount:
      queryType === AMOUNT_TYPES.BUY
        ? queryAmount
        : previousQuote?.buy_amount?.toFixed(2),
    amountType: queryType || AMOUNT_TYPES.SELL,
  });

  const [quotingResponseTime, setQuotingResponseTime] = React.useState<
    number | null
  >(null);
  const [amountConfirmStep, setAmountConfirmStep] =
    React.useState<boolean>(false);

  const [tradeError, setTradeError] = React.useState<any>();
  const [sourceCurrency, setSourceCurrency] = React.useState<string>(
    querySourceCurrency ||
      getInitialSourceCurrency(debitSource, selectedBalance, previousQuote)
  );

  const currencyList = currencies.map(({ currency_code }) => currency_code);

  const [targetCurrency, setTargetCurrency] = React.useState<string>(
    previousQuote?.currency_buy ||
      (selectedBalance
        ? currencyList.find(
            (currency) => currency !== selectedBalance?.currency
          )
        : queryTargetCurrency || defaultTransferValues.buy.currency)
  );

  const [formData, setFormData] = React.useState<QuoteWithClientRef>(
    previousQuote
      ? {
          ...previousQuote,
          quote_rate: null,
        }
      : ({
          client_ref: activeClient?.cli_reference,
          value_date: valueDate.getNextAvailableValueDate(
            getCuttOffTime(sourceCurrency, currencies),
            getCuttOffTime(targetCurrency, currencies),
            bankHolidays,
            activeClient
          ),
          quote_rate: null,
        } as QuoteWithClientRef)
  );

  function hasAmountForOperation(amnt: typeof amount) {
    return amnt?.amountType === AMOUNT_TYPES.SELL
      ? amnt?.sourceAmount &&
          parseFloat(amnt?.sourceAmount.replace(/,/g, "")) > 0
      : amnt?.targetAmount &&
          parseFloat(amnt?.targetAmount.replace(/,/g, "")) > 0;
  }

  function isQuoteUnderLimit(amnt: typeof amount) {
    if (amnt.amountType === AMOUNT_TYPES.SELL && !amnt.sourceAmount) {
      return true;
    }

    if (amnt.amountType === AMOUNT_TYPES.BUY && !amnt.targetAmount) {
      return true;
    }

    return amnt.amountType === AMOUNT_TYPES.SELL
      ? parseFloat(amnt.sourceAmount?.replace(/,/g, "")) <=
          getQuoteLimit(sourceCurrency, currencies)
      : parseFloat(amnt.targetAmount?.replace(/,/g, "")) <=
          getQuoteLimit(targetCurrency, currencies);
  }

  const shouldFetchQuotes =
    isNewTrade &&
    isQuoteUnderLimit(amount) &&
    hasAmountForOperation(amount) &&
    Utils.isEmpty(query?.instructPaymentTradeID);

  const useTheSameCurrency = sourceCurrency === targetCurrency;
  const isPrivateClient = activeClient?.cty_value === "PRIVATE";
  // TODO: validation, error handling, blah blah :D

  const {
    data: quote,
    isLoading: quoting,
    error: quoteError,
  } = useQuery<
    GetQuoteQuery["getQuote"],
    GetQuoteQuery,
    GetQuoteQueryVariables
  >(
    shouldFetchQuotes ? GetQuoteDocument : null,
    {
      currency_sell: sourceCurrency,
      currency_buy: targetCurrency,
      sell_amount:
        (isNewTrade || isPrivateClient) &&
        amount.amountType === AMOUNT_TYPES.SELL
          ? parseFloat(amount.sourceAmount?.replace(/,/g, ""))
          : undefined,
      buy_amount:
        (isNewTrade || isPrivateClient) &&
        amount.amountType === AMOUNT_TYPES.BUY
          ? parseFloat(amount.targetAmount?.replace(/,/g, ""))
          : undefined,
      client_ref: formData.client_ref,
      value_date: formData.value_date,
    },
    [quoteCounter, shouldFetchQuotes],
    () => {
      toast.hideNotify();
      setQuotingResponseTime(Date.now());
    }
  );

  React.useEffect(() => {
    if (beneficiary && !isNewTrade) {
      setTargetCurrency(beneficiary.currency);
      if (sourceCurrency == beneficiary.currency) {
        setSourceCurrency(
          currencyList.find((currency) => currency !== beneficiary.currency)
        );
      }
      setTimeout(() => {
        setQuoteCounter(quoteCounter + 1);
      }, 200);
    }
  }, [beneficiary, isNewTrade]);

  React.useEffect(() => {
    if (quoting && query?.amountType === AMOUNT_TYPES.BUY) {
      setAmount({ ...amount, sourceAmount: "" });
    }
    Utils.getBankHolidays().then((holidays) => {
      setBankHolidays(holidays);
      setFormData({
        ...formData,
        value_date: valueDate.getNextAvailableValueDate(
          getCuttOffTime(sourceCurrency, currencies),
          getCuttOffTime(targetCurrency, currencies),
          holidays,
          activeClient
        ),
      });
    });
  }, [sourceCurrency, targetCurrency]);

  React.useEffect(() => {
    if (quote && quote.quote_rate && !quoting) {
      setFormData({
        ...formData,
        quote_rate: useTheSameCurrency ? 1 : quote.quote_rate,
      });
      setQuotingResponseTime(Date.now());
    }
  }, [quote, quoting]);

  React.useEffect(() => {
    let toastMessage = null;
    if (quoteError && quoteError.errors) {
      toastMessage = {
        message: messages.quoteFetch.message,
        status: TOAST_STATUSES.CRITICAL,
      };
    }

    if (tradeError && tradeError?.errors) {
      toastMessage = {
        message: messages.bookTrade.message,
        status: TOAST_STATUSES.CRITICAL,
      };
    }

    if (toastMessage) toast.notify(toastMessage);
  }, [tradeError, quoteError]);

  React.useEffect(() => {
    if (amount.amountType === AMOUNT_TYPES.SELL) {
      calculateTargetAmount();
    } else if (amount.amountType === AMOUNT_TYPES.BUY) {
      calculateSourceAmount();
    }
  }, [amount.targetAmount, amount.sourceAmount, quote?.quote_rate]);

  const calculateSourceAmount = React.useCallback(() => {
    const quoteRate = quote?.quote_rate || previousQuote?.quote_rate || null;

    if (quoteRate) {
      setAmount({
        ...amount,
        sourceAmount: (
          parseFloat(amount.targetAmount?.replace(/,/g, "")) / quoteRate
        ).toFixed(2),
      });
    }
  }, [quote?.quote_rate, previousQuote?.quote_rate, amount.targetAmount]);

  const calculateTargetAmount = React.useCallback(() => {
    const quoteRate = quote?.quote_rate || previousQuote?.quote_rate || null;

    if (quoteRate) {
      setAmount({
        ...amount,
        targetAmount: amount.sourceAmount
          ? (
              parseFloat(amount.sourceAmount?.replace(/,/g, "")) * quoteRate
            ).toFixed(2)
          : null,
      });
    }
  }, [quote?.quote_rate, previousQuote?.quote_rate, amount.sourceAmount]);

  const onConfirmAmount = (trade: Trade) => {
    const hasSourceAmount =
      amount.sourceAmount && !isNaN(parseFloat(amount.sourceAmount));
    const hasTargetAmount =
      amount.targetAmount && !isNaN(parseFloat(amount.targetAmount));
    const newQuote = {
      ...formData,
      currency_sell: sourceCurrency,
      currency_buy: targetCurrency,
      quote_rate: quote?.quote_rate,
      sell_amount:
        hasSourceAmount && parseFloat(amount.sourceAmount?.replace(/,/g, "")),
      buy_amount:
        hasTargetAmount && parseFloat(amount.targetAmount?.replace(/,/g, "")),
    };

    logWebActivity({
      activeClient,
      action: "TRADE",
      id: trade.reference,
      message: `traded ${newQuote.currency_buy} ${newQuote.buy_amount} against ${newQuote.currency_sell} ${newQuote.sell_amount} ${trade.reference}`,
    });

    onComplete({ quote: newQuote, trade });
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    setAmountConfirmStep(!amountConfirmStep);
  };

  // TODO move it to place where it can be reusable many times!
  //@see: src/components/FastQuoteForm/FastQuoteForm.tsx:38
  const onChangeInputAmount = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  if (amountConfirmStep) {
    return (
      <AmountConfirmation
        key={`current-quote-rate-${quote?.ID}}-${quote?.quote_rate}`}
        quote={{ ...(quote || previousQuote), client_ref: formData.client_ref }}
        quotingResponseTime={quotingResponseTime}
        quoting={quoting}
        onCancelConfirm={submitHandler}
        onConfirmAmount={onConfirmAmount}
        debitSource={debitSource}
        onQuoteCountdownComplete={() => setQuoteCounter(quoteCounter + 1)}
        onBookTradeError={(error) => setTradeError(error)}
        trade={
          {
            sold_amount: parseFloat(amount.sourceAmount?.replace(/,/g, "")),
            sold_currency: sourceCurrency,
            bought_amount: parseFloat(amount.targetAmount?.replace(/,/g, "")),
            bought_currency: targetCurrency,
            value_date: formData.value_date,
            quote_rate: formData.quote_rate,
            trade_date: formData.value_date,
          } as Trade
        }
      />
    );
  }

  const buyCurrenciesList = (): string[] => {
    if (Utils.isNotEmpty(query?.balance_currency)) {
      return currencyList.filter(
        (currency) => currency !== query?.balance_currency
      );
    }
    return currencyList;
  };

  const sendCurrenciesList = (): string[] => {
    const { targetCurrency, balance_currency } = query;
    let currencies: string[] = selectedBalance?.currency
      ? [selectedBalance.currency]
      : currencyList;

    if (balance_currency) return [balance_currency as string];

    if (query?.beneficiary && Utils.isNotEmpty(targetCurrency)) {
      currencies = currencies.filter((currency) => currency !== targetCurrency);
    }

    if (isPrivateClient && Utils.isNotEmpty(beneficiary)) {
      return currencies.filter((currency) => currency !== beneficiary.currency);
    }

    return currencies;
  };

  const minDate = valueDate.getNearestBusinessDate(
    new Date(),
    bankHolidays,
    activeClient
  );

  const hasQuote =
    quote?.quote_rate !== null && quote?.quote_rate !== undefined;

  if (hasQuote) {
    logWebActivity({
      activeClient,
      action: "QUOTE",
      id: quote.ID,
      message: `requested quote : buy_currency=${
        quote.currency_buy
      } sell_currency=${quote.currency_sell} ${
        quote.buy_amount
          ? "amount_sell=" + quote.buy_amount
          : "amount_buy=" + quote.sell_amount
      }`,
    });
  }

  return (
    <form onSubmit={submitHandler} className="space-y-6">
      <h1 className="block w-full text-teal-700 text-2xl">
        How much would you like to transfer?
      </h1>

      {!isQuoteUnderLimit(amount) && (
        <Alert status="critical">
          <div>
            Your online limit for{" "}
            {(amount.amountType === AMOUNT_TYPES.SELL
              ? sourceCurrency
              : targetCurrency
            ).toUpperCase()}{" "}
            is{" "}
            {amount.amountType === AMOUNT_TYPES.SELL
              ? Utils.parseAmountByCurrencyCode(
                  getQuoteLimit(sourceCurrency, currencies),
                  sourceCurrency
                )
              : Utils.parseAmountByCurrencyCode(
                  getQuoteLimit(targetCurrency, currencies),
                  targetCurrency
                )}
            . Please{" "}
            <Link href="/help" className="text-green-600">
              contact your dealer
            </Link>{" "}
            if you need to make a larger trade.
          </div>
        </Alert>
      )}

      <div>
        <MoneyInput
          name="sell"
          label="I have (sell)"
          disableDropdown={Utils.isNotEmpty(query?.balance_currency)}
          currencies={sendCurrenciesList()}
          onKeyPress={onChangeInputAmount}
          onAmountChange={Utils.debounce((e) => {
            setAmount({
              ...amount,
              sourceAmount: e?.target.value,
              amountType: AMOUNT_TYPES.SELL,
            });
          })}
          amount={amount.sourceAmount}
          currency={sourceCurrency}
          onCurrencyChange={(currency) => {
            const currencyValue = currency.selectedItem.value;
            if (targetCurrency === currencyValue) {
              setTargetCurrency(sourceCurrency);
            }
            setSourceCurrency(currencyValue);
            Utils.debounce(() => {
              setQuoteCounter(quoteCounter + 1);
            });
          }}
        />
        {debitSource === DebitSource.Balance && (
          <BalanceLabel
            amount={selectedBalance.balance}
            currency={selectedBalance.currency}
          />
        )}
      </div>

      <MoneyInput
        name="buy"
        label="I want (buy)"
        disableDropdown={Utils.isNotEmpty(query?.beneficiary)}
        currencies={
          beneficiary && !isNewTrade
            ? [beneficiary.currency]
            : buyCurrenciesList()
        }
        onKeyPress={onChangeInputAmount}
        onAmountChange={Utils.debounce((e) => {
          setAmount({
            ...amount,
            targetAmount: e.target.value,
            amountType: AMOUNT_TYPES.BUY,
          });
        })}
        amount={amount.targetAmount}
        currency={targetCurrency}
        onCurrencyChange={(currency) => {
          const currencyValue = currency.selectedItem.value;
          if (sourceCurrency === currencyValue) {
            setSourceCurrency(targetCurrency);
          }
          setTargetCurrency(currencyValue);
          Utils.debounce(() => {
            setQuoteCounter(quoteCounter + 1);
          });
        }}
      />

      <div className="flex justify-between">
        <p className="text-lg text-gray-700">Exchange rate</p>

        <div className="flex items-center">
          {hasQuote && (
            <QuoteCountdown
              // TODO: Temporarily using this 3rd party component until our design system Countdown unmounting issues are fixed
              _key={
                !quoting && `${quote?.ID}_${quoting}_${quotingResponseTime}`
              }
              timestamp={quotingResponseTime}
              disabled={useTheSameCurrency}
              isPlaying={!quoting && !!quote?.quote_rate}
              onComplete={() => {
                setQuoteCounter(quoteCounter + 1);
              }}
            />
          )}

          {shouldFetchQuotes &&
            (!quote?.quote_rate ? (
              <div className="ml-3 text-sm text-gray-500">Loading</div>
            ) : (
              <div className="flex flex-col items-end">
                <div className="ml-4 text-lg text-gray-600">
                  {quote.quote_rate.toFixed(4)}
                </div>
                <div className="ml-4 text-xs text-gray-400">
                  {`(${(1 / quote.quote_rate).toFixed(4)})`}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex justify-between align-center">
        <div className="text-sm text-gray-500">
          Value Date:{" "}
          {valueDate.transformValueDate(
            formData.value_date,
            valueDate.valueDateReturnFormat,
            valueDate.valueDateDisplayFormat
          )}
        </div>
        <div data-testid="date-picker">
          <DatePicker
            isNonEEACorporateClient={
              activeClient.cty_value === "CORPORATE" && !activeClient.is_EEA
            }
            minDate={minDate}
            bankHolidays={bankHolidays}
            limitOfAvailableDays={
              process.env.NEXT_PUBLIC_ENABLE_TRADE_FORWARDS &&
              activeClient.cty_value === "CORPORATE" &&
              !activeClient.is_EEA &&
              activeClient.cli_can_trade_forward
                ? 365
                : 4
            }
            onChange={(newDate) => {
              setFormData({
                ...formData,
                value_date: valueDate.formatDateToInput(newDate),
              });
            }}
            customInput={
              <div className="flex justify-between align-center cursor-pointer">
                <div className="flex justify-between align-center rounded-full bg-green-600 p-1 mr-2">
                  <CalendarIcon className="w-4 stroke-current text-white m-0" />
                </div>
                <span className="text-base text-gray-600">
                  Change the value date
                </span>
              </div>
            }
            popperPlacement="bottom-start"
            popperModifiers={[
              {
                name: "flip",
                enabled: false,
              },
              {
                name: "preventOverflow",
                options: {
                  rootBoundary: "viewport",
                  tether: false,
                  altAxis: false,
                },
              },
              {
                name: "offset",
                options: {
                  offset: [-110, 10],
                },
              },
            ]}
          />
        </div>
      </div>

      <Footer
        quote={quote}
        quoting={quoting}
        quoteError={quoteError}
        allowedToTrade={activeClient.allowed_to_trade}
      />
    </form>
  );
};

export default QuoteForm;
