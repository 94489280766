import { FormEvent, useEffect, useRef, useState } from "react";

import { Button, Input } from "@clear-treasury/design-system";
import useQuery from "../../hooks/useQuery";
import { useApp } from "../../ctx/AppProvider";
import { TOAST_STATUSES, useToast } from "../../ctx/ToastProvider";
import { CognitoAttributes } from "@aws-amplify/ui/dist/types/types/authenticator/user";
import Utils from "../../core/Utils";
import {
  CancelCodeDocument,
  CancelCodeQuery,
  CancelCodeQueryVariables,
  RequestCodeDocument,
  RequestCodeQuery,
  RequestCodeQueryVariables,
} from "../../graphql/gql-types";

type Error = {
  message: string;
};

type Errors = {
  verificationCode?: Error;
};

interface QueryVariables {
  To: string;
  Code?: string;
}

interface VerificationFormProps {
  onComplete: (code: string) => void;
  onCancel: () => void;
}

let currentOPTCount = 0;
const OTP_VERIFICATION_MAX_RETRIES = 5;

const VerificationForm = ({
  onComplete,
  onCancel,
}: VerificationFormProps): JSX.Element => {
  const [amplifyUser] = useApp<CognitoAttributes>((store) => store.amplifyUser);
  const toast = useToast();
  const verificationCode = useRef<HTMLInputElement | null>(null);

  const [errors, setErrors] = useState<Errors>({});
  const [verifying, setVerifying] = useState<boolean>(false);
  const [cancelCode, setCancelCode] = useState<boolean>(false);
  const [sendOtpCount, setSendOtpCount] = useState<boolean>(false);
  const [subMessage, toggleSubMessage] = useState<boolean>(false);

  const { phone_number } = amplifyUser;
  const [queryVariables, setQueryVariables] = useState<QueryVariables>({
    To: phone_number,
  });

  // Making a request to receive verify code.
  const { data } = useQuery<
    RequestCodeQuery["requestCode"],
    RequestCodeQuery,
    RequestCodeQueryVariables
  >(
    !sendOtpCount ? RequestCodeDocument : null,
    queryVariables,
    [currentOPTCount],
    () => setVerifying(false),
    () => setVerifying(false)
  );

  // Canceling the request above
  useQuery<
    CancelCodeQuery["cancelCode"],
    CancelCodeQuery,
    CancelCodeQueryVariables
  >(cancelCode ? CancelCodeDocument : null, { Sid: data && data.sid }, [
    cancelCode,
  ]);

  // This effect will be called when we have added invalid verify code.
  useEffect(() => {
    const currentToast = toast.toasts[toast.toasts.length - 1];
    if (
      currentToast?.context &&
      currentToast.status === TOAST_STATUSES.CRITICAL
    ) {
      setVerifying(false);
      setErrors({
        verificationCode: {
          message:
            currentToast?.context?.message === "Invalid MFA code"
              ? "You must enter a valid verification code"
              : "",
        },
      });
    }
  }, [toast.toasts.length]);

  // This effect will be called when we have reached the max re-try codes.
  useEffect(() => {
    const isReachedLimit = currentOPTCount === OTP_VERIFICATION_MAX_RETRIES;

    if (isReachedLimit) {
      toast.hideNotify();
      setErrors({
        verificationCode: {
          message: "Max OTP send limit reached",
        },
      });
    }

    setSendOtpCount(isReachedLimit);
  }, [currentOPTCount]);

  // Reset local component state
  useEffect(
    () => () => {
      currentOPTCount = 0;
      setErrors({});
      toast.hideNotify(true);
      setCancelCode(false);
      setSendOtpCount(false);
      setVerifying(false);
    },
    []
  );

  const verifyCode = (event: FormEvent) => {
    event.preventDefault();
    setErrors({});

    toggleSubMessage(false);
    if (!verificationCode.current.value) {
      setErrors({
        verificationCode: {
          message: "You must enter a verification code",
        },
      });

      return false;
    }

    setQueryVariables({ ...queryVariables });

    setVerifying(true);
    onComplete(verificationCode.current.value);
  };

  const resendCode = (event: FormEvent) => {
    event.preventDefault();
    if (sendOtpCount) return;

    currentOPTCount += 1;

    verificationCode.current.value = "";
    toast.hideNotify();
    setErrors({});
    toggleSubMessage(true);
    setQueryVariables({ ...queryVariables });
  };

  const last4Digits = phone_number?.slice(-4);
  const maskedPhoneNumber = last4Digits?.padStart(phone_number.length, "*");

  const verifyOtpDisabled = verifying || sendOtpCount;
  return (
    <form onSubmit={verifyCode}>
      <div className="block w-full mb-6">
        <h2 className="text-theme-color-on-surface text-2xl">
          Enter the verification code
        </h2>

        <p className="text-l text-gray-500">
          {sendOtpCount
            ? "Max resend limit reached. Please try again later."
            : `We have sent a 6 digit verification code to ${maskedPhoneNumber} It may take a few moments to arrive`}
        </p>
      </div>

      <Input
        ref={verificationCode}
        name="verificationCode"
        label="Verification code"
        placeholder="Enter code"
        errors={errors}
        autoComplete="one-time-code"
      />
      {Utils.isEmpty(errors) && subMessage && (
        <span className="block text-saphire-900 text-sm px-1 font-bold">
          {`A new code has been send to your phone number`}
        </span>
      )}
      <span
        data-testid="resent-verify-code"
        onClick={resendCode}
        className="block cursor-pointer text-gray-600 text-sm mb-16 select-none"
      >
        Resend verification code
      </span>

      <div className="flex justify-center gap-4 mt-6">
        {!sendOtpCount && (
          <button
            type="button"
            onClick={() => onCancel()}
            className="inline-flex items-center justify-center gap-2 border border-transparent ring-theme-button-primary text-center leading-snug rounded-theme-radius focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-transparent transition-all duration-300 cursor-pointer hover:shadow-md hover:cursor-pointer disabled:cursor-not-allowed bg-theme-button-secondary text-theme-color-on-secondary hover:bg-theme-button-secondary-hover py-2 px-8"
          >
            Cancel
          </button>
        )}
        {sendOtpCount && <div onClick={() => onCancel()}>Back</div>}
        <Button
          loading={verifying}
          size={Button.Size.LARGE}
          disabled={verifyOtpDisabled}
          emphasis={
            verifyOtpDisabled
              ? Button.Emphasis.SECONDARY
              : Button.Emphasis.PRIMARY
          }
        >
          Verify Code
        </Button>
      </div>
    </form>
  );
};

export default VerificationForm;
