import * as React from "react";
import { FC } from "react";

export interface FormLabelProps {
  label: string;
  subLabel?: string;
  clasName?: string;
  value?: string | number;
  children?: React.ReactNode;
}

const FormLabel: FC<FormLabelProps> = ({
  label,
  value,
  clasName,
  subLabel,
  children,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={`flex justify-between my-4 ${clasName ? clasName : ""}`}
    >
      <div>
        <span className="text-lg text-teal-700">{label}</span>
        {subLabel && (
          <span className="text-xs text-gray-400 block">{subLabel}</span>
        )}
      </div>
      {value && <span className="text-lg text-gray-600"> {value}</span>}
      {children}
    </div>
  );
};

export default FormLabel;
