import * as React from "react";
import { Alert, Button, Input } from "@clear-treasury/design-system";
import { Error } from "@clear-treasury/design-system/dist/components/form-field/FormField";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";

import { SearchIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { useRouter } from "next/router";
import Utils from "../../core/Utils";
import { useApp } from "../../ctx/AppProvider";
import { IToastProps, TOAST_STATUSES, useToast } from "../../ctx/ToastProvider";
import messages from "../../data/i18n/en.json";
import { useArrayMutation } from "../../hooks/useArrayMutation";
import SelectBeneficiaryRow from "../SelectBeneficiaryRow";
import { IBeneficiaryFormData } from "../beneficiary-form/BeneficiaryForm";
import {
  Beneficiary,
  Client,
  CreateBeneficiaryDocument,
  Trade,
} from "../../graphql/gql-types";
import { QuoteWithClientRef } from "../quote-form/QuoteForm";
import { WithRequiredProperty } from "../../lib/UtilityTypes";

export interface ISelectBeneficiaryProps {
  quote?: QuoteWithClientRef;
  selected?: IBeneficiaryFormData[];
  existingTrade: Trade;
  selectedBeneficiaries: IBeneficiaryFormData[];
  beneficiaries: WithRequiredProperty<
    Beneficiary,
    "country_code" | "account_name" | "account_number"
  >[];
  beneficiariesLoading: boolean;
  isNewTrade?: boolean;
  addBeneficiary: () => void;
  onComplete?: (data: IBeneficiaryFormData[]) => void;
  setSelectedBeneficiaries: React.Dispatch<
    React.SetStateAction<IBeneficiaryFormData[]>
  >;
  preselected?: boolean;
  disableNextStep: boolean;
}

export type Errors = {
  form?: Error;
  [fieldName: string]: Error;
};

const SelectBeneficiary = ({
  quote,
  selected,
  existingTrade,
  onComplete,
  addBeneficiary,
  beneficiaries = [],
  beneficiariesLoading,
  isNewTrade,
  preselected,
  disableNextStep,
  selectedBeneficiaries,
  setSelectedBeneficiaries,
}: ISelectBeneficiaryProps): JSX.Element => {
  const router = useRouter();
  const { query } = router;

  const isSameCurrencyTransfer = quote.currency_sell === quote.currency_buy;

  const toast: IToastProps = useToast();
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const search = useRef<HTMLInputElement | null>(null);
  const [errors, setErrors] = useState<Errors>({});
  const [accAmount, setAccAmount] = useState<number>(0);
  const [activeBeneficiary, setActiveBeneficiary] = useState<string | null>(
    null
  );

  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState<
    WithRequiredProperty<
      Beneficiary,
      "country_code" | "account_name" | "account_number"
    >[]
  >([]);

  const [migrateBeneficiaries, setMigrateBeneficiaries] = useState<{
    beneficiaries: IBeneficiaryFormData[];
    create: boolean;
  }>(null);

  const getTradeAmount = () => {
    if (Utils.isNotEmpty(query?.instructPaymentTradeID)) {
      return existingTrade?.outstanding_amount;
    }

    return (
      quote.buy_amount ||
      +(quote.sell_amount * +(quote.quote_rate?.toFixed(4) ?? 0)).toFixed(2)
    );
  };

  const getSelectedCurrencyBeneficiaries = () => {
    return beneficiaries.filter(
      (beneficiary) => beneficiary.currency === quote.currency_buy
    );
  };

  const {
    data: migratedBeneficiaries,
    error: migratedBeneficiariesError,
    loading: migratedBeneficiariesLoading,
  } = useArrayMutation<any>(
    migrateBeneficiaries?.create ? CreateBeneficiaryDocument : null,
    migrateBeneficiaries?.beneficiaries.map(({ beneficiary }) => {
      let beneficiaryData: any = {
        client_ref: quote.client_ref,
        email: beneficiary.email,
        alias: beneficiary.alias,
        country_code: beneficiary.country_code,
        currency: beneficiary.currency,
        iban: beneficiary.iban,
        account_number: beneficiary.account_number || beneficiary.iban,
        sort_code: beneficiary.sort_code,
      };

      if (!isSameCurrencyTransfer) {
        beneficiaryData = {
          ...beneficiaryData,
          nickname: beneficiary.account_name,
          account_name: beneficiary.account_name,
          routing_number: beneficiary.routing_number,
          bank_name: beneficiary.bank_name,
          swift: beneficiary.swift,
        };
      }

      return beneficiaryData;
    })
  );

  useEffect(() => {
    if (
      migratedBeneficiariesError ||
      (migrateBeneficiaries?.create &&
        migrateBeneficiaries?.beneficiaries?.length !==
          migratedBeneficiaries.length)
    ) {
      toast.notify({
        message: messages.fxopsBeneficiaries.message,
        status: TOAST_STATUSES.CRITICAL,
      });
    }
    if (migratedBeneficiaries.length) {
      setMigrateBeneficiaries({
        beneficiaries: [],
        create: false,
      });

      const existingFxopsBeneficiaries = selectedBeneficiaries.filter(
        ({ beneficiary }) => !beneficiary.isIfBeneficiary
      );
      const createdFxopsBeneficiaries: IBeneficiaryFormData[] =
        migrateBeneficiaries.beneficiaries.map((item, index) => {
          const migratedBeneficiary = JSON.parse(
            migratedBeneficiaries[index].data
          ).beneficiary;
          const { address, bankAccounts, contact, id, identity, title } =
            migratedBeneficiary;

          return {
            ...item,
            beneficiary: {
              id: bankAccounts[0].id,
              email: contact.email,
              alias: title,
              account_name: identity.legalName,
              bank_name: identity.legalName,
              currency: bankAccounts[0].currency,
              country_code: address.country,
              isIfBeneficiary: id,
              ifBankAccountId: bankAccounts[0].id,
            },
          };
        });
      onComplete([...existingFxopsBeneficiaries, ...createdFxopsBeneficiaries]);
    }
  }, [
    migratedBeneficiaries,
    migratedBeneficiariesError,
    migratedBeneficiariesLoading,
  ]);

  useEffect(() => {
    if (beneficiaries && beneficiaries.length > 0) {
      let items = preselected
        ? beneficiaries
        : getSelectedCurrencyBeneficiaries();

      if (preselected && Utils.isNotEmpty(selected)) {
        items = items.filter((item) => item.id === selected[0]?.beneficiary.id);
      }

      setFilteredBeneficiaries(items);
    }
  }, [beneficiaries, selected]);

  const searchBeneficiaries = (event: any) => {
    const value = event.target.value;

    if (!value.length) {
      setFilteredBeneficiaries(getSelectedCurrencyBeneficiaries());
    } else {
      const searchFiltered = filteredBeneficiaries.filter(({ account_name }) =>
        account_name.toLowerCase().match(new RegExp(value, "ig"))
      );

      setFilteredBeneficiaries(searchFiltered);
    }
  };

  const getTotalBeneficiaryAmount = () =>
    selectedBeneficiaries.reduce((acc, { amount }) => {
      if (amount) acc += amount;

      return acc;
    }, 0);

  const updateSelectedBeneficiary = useCallback(
    (id: string, update: Partial<IBeneficiaryFormData>) => {
      const updatedBeneficiaries = selectedBeneficiaries.map(
        (selectedBeneficiary) =>
          selectedBeneficiary.beneficiary.id === id
            ? {
                ...selectedBeneficiary,
                ...update,
              }
            : selectedBeneficiary
      );

      setSelectedBeneficiaries(updatedBeneficiaries);
    },
    [selectedBeneficiaries]
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const newErrors: Errors = {};
    let hasErrors = false;

    const itemsWithMissingReason = selectedBeneficiaries.filter(
      (item) => !item.reason
    );

    if (selectedBeneficiaries.length === 0) {
      hasErrors = true;
      newErrors.form = { message: "Choose a beneficiary or add a new one" };
    }

    // Validating reference field before proceed further
    selectedBeneficiaries.forEach((item) => {
      const { id, currency, country_code: country } = item.beneficiary;

      if (Utils.isEmpty(item.payment_reference?.trim())) {
        hasErrors = true;
        newErrors[`reference-${id}`] = {
          message: "You must enter a reference",
        };
      } else {
        const fieldValue = item.payment_reference.trim();
        const isGBPtoGB = currency === "GBP" && country === "GB";

        // When the currency is GBP and the country is GB if the value entered exceeds 18 alphanumeric characters
        // including spaces show the User an error message
        if (isGBPtoGB && fieldValue.length > 18) {
          hasErrors = true;
          newErrors[`reference-${id}`] = {
            message: "Reference must not exceed 18 characters",
          };
        }

        // For all other currency and country pairs if the value entered exceeds 120 alphanumeric characters
        // including spaces show the User an error message
        if (fieldValue.length > 120) {
          hasErrors = true;
          newErrors[`reference-${id}`] = {
            message: "Reference must not exceed 120 characters",
          };
        }
      }
    });

    if (itemsWithMissingReason.length > 0) {
      hasErrors = true;
      for (const item of itemsWithMissingReason) {
        newErrors[`reason-${item.beneficiary.id}`] = {
          message: "You must select a reason",
        };
      }
    }

    const totalBeneficiaryAmount = getTotalBeneficiaryAmount();
    for (const item of selectedBeneficiaries) {
      if (totalBeneficiaryAmount > getTradeAmount()) {
        hasErrors = true;
        newErrors[`amount-${item.beneficiary.id}`] = {
          message: "Total amount exceeds the trade value",
        };
      }

      if (!item.amount || item.amount <= 0) {
        hasErrors = true;
        newErrors[`amount-${item.beneficiary.id}`] = {
          message: "You must enter an amount",
        };
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
      return false;
    }

    const ifBeneficiaries = selectedBeneficiaries.filter(
      (item) => item.beneficiary.isIfBeneficiary
    );

    const fxopsBeneficiaries = selectedBeneficiaries.filter(
      (item) => !item.beneficiary.isIfBeneficiary
    );

    if (isSameCurrencyTransfer && fxopsBeneficiaries.length) {
      setMigrateBeneficiaries({
        beneficiaries: fxopsBeneficiaries,
        create: true,
      });
    } else if (!isSameCurrencyTransfer && ifBeneficiaries.length) {
      setMigrateBeneficiaries({
        beneficiaries: ifBeneficiaries,
        create: true,
      });
    } else {
      setErrors(newErrors);
      onComplete(selectedBeneficiaries);
    }
  };

  const shouldDisabledNextButton = disableNextStep || beneficiariesLoading;
  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex w-full items-center">
        <div className="flex-1">
          <h2 className="text-theme-color-on-surface text-2xl">
            {!preselected ? "Choose a beneficiary" : "Select a reason"}
          </h2>
          <p className="text-lg text-gray-500">
            {!preselected
              ? "Please choose or add a beneficiary"
              : "Please select a reason for transfer"}
          </p>
        </div>
        {!preselected && activeClient?.allowed_to_create_beneficiaries && (
          <div className="-mr-4 text-gray-600 text-lg">
            <Button
              size={Button.Size.MEDIUM}
              emphasis={Button.Emphasis.TRANSPARENT}
              onClick={addBeneficiary}
            >
              <PlusCircleIcon width="28" className="text-green-600" />
              Add a new beneficiary
            </Button>
          </div>
        )}
      </div>

      {!preselected && (
        <div className="relative">
          <Input
            ref={search}
            name="search"
            placeholder="Search"
            onChange={searchBeneficiaries}
          />
          <SearchIcon
            width={24}
            className="absolute top-1/4 right-4 text-gray-400"
          />
        </div>
      )}

      <div className="space-y-4">
        {beneficiariesLoading && <div>Loading...</div>}
        {!beneficiariesLoading &&
          filteredBeneficiaries?.map((item: Beneficiary) => {
            let isActive;
            const selectedBeneficiary = selectedBeneficiaries.find(
              ({ beneficiary }) => beneficiary.id === item.id
            );

            if (Utils.isNotEmpty(activeBeneficiary)) {
              isActive = activeBeneficiary === item.id;
            } else {
              // Using the selectedBeneficiaries as a stack interface
              // And keeping past active beneficiary
              isActive = Utils.isNotEmpty(selectedBeneficiaries)
                ? selectedBeneficiaries[selectedBeneficiaries.length - 1]
                    .beneficiary.id === item.id
                : true;
            }

            return (
              <SelectBeneficiaryRow
                toggleFocus={(id) => setActiveBeneficiary(id)}
                key={item.id}
                item={item}
                isActive={isActive}
                setAccAmount={setAccAmount}
                errors={errors}
                setErrors={setErrors}
                accAmount={accAmount}
                tradeAmount={getTradeAmount()}
                clientType={activeClient.cty_value}
                preselected={preselected}
                beneficiaries={filteredBeneficiaries}
                setSelectedBeneficiaries={setSelectedBeneficiaries}
                selectedBeneficiaries={selectedBeneficiaries}
                updateSelectedBeneficiary={updateSelectedBeneficiary}
                selectedBeneficiary={selectedBeneficiary}
              />
            );
          })}
      </div>

      <div
        className={`flex border-b border-gray-200 pb-6 ${
          errors.form ? "justify-between" : "justify-end"
        }`}
      >
        {errors.form && (
          <Alert status={Alert.Status.CRITICAL} text={errors.form.message} />
        )}
      </div>

      <div className="flex items-center justify-end">
        {Utils.isNotEmpty(query?.instructPaymentTradeID) && (
          <Button
            size={Button.Size.LARGE}
            emphasis={Button.Emphasis.TRANSPARENT}
            onClick={(ev) => {
              ev.preventDefault();
              router.back();
            }}
          >
            <ArrowLeftIcon width="16" />
            Back
          </Button>
        )}
        <div className="w-2/5 flex flex-grow justify-end gap-5 mr-5">
          {isNewTrade && !Utils.isNotEmpty(query?.instructPaymentTradeID) && (
            <Button
              emphasis="secondary"
              disabled={shouldDisabledNextButton}
              size={Button.Size.LARGE}
              onClick={() => onComplete([])}
            >
              Skip
            </Button>
          )}
          <Button
            emphasis={
              shouldDisabledNextButton || Utils.isEmpty(beneficiaries)
                ? "secondary"
                : "primary"
            }
            disabled={shouldDisabledNextButton || Utils.isEmpty(beneficiaries)}
            size={Button.Size.LARGE}
          >
            Continue
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SelectBeneficiary;
