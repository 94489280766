import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Button } from "@clear-treasury/design-system";

import Utils from "../../core/Utils";
import { logWebActivity } from "../../lib/logger";
import { useApp } from "../../ctx/AppProvider";
import { useMutation } from "../../hooks/useMutation";
import { useToast, TOAST_STATUSES } from "../../ctx/ToastProvider";
import { InstructPaymentDocument } from "../../graphql/gql-types";
import { DebitSource, TransferData } from "../../pages/transfer";
import FormLabel from "../FormLabel/FormLabel";
import { IBeneficiaryFormData } from "../beneficiary-form/BeneficiaryForm";
import { QuoteWithClientRef } from "../quote-form/QuoteForm";
import { formatValueDate } from "../quote-form/lib/valueDate";
import FormHeading from "./ui/FormHeading";

import type {
  Trade,
  Client,
  InstructPaymentMutation,
  InstructPaymentMutationVariables,
} from "../../graphql/gql-types";

import messages from "../../data/i18n/en.json";

interface ConfirmPayFormProps {
  debitSource?: DebitSource;
  quote: QuoteWithClientRef;
  reason: string;
  existingTrade?: Trade;
  beneficiaries: IBeneficiaryFormData[];
  stepBack: () => void;
  onComplete?: (transferData: TransferData) => void;
}

const ConfirmPayForm = ({
  quote,
  reason,
  beneficiaries,
  onComplete,
  stepBack,
  existingTrade,
}: ConfirmPayFormProps): JSX.Element => {
  const router = useRouter();
  const { query } = router;
  const toast = useToast();
  const [activeClient] = useApp<Client>((store) => store.activeClient);

  const {
    // data: payment,
    error: paymentError,
    doMutation: instructPaymentMutation,
    isLoading: instructingPayment,
  } = useMutation<
    InstructPaymentMutation["instructPayment"],
    InstructPaymentMutationVariables["input"]
  >(InstructPaymentDocument, undefined, (payment, input) => {
    logWebActivity({
      activeClient,
      action: "OPI",
      id: payment.PaymentGuid,
      message: `inserted OPI ${input.currency}${input.amount} for trade : ${existingTrade.reference}`,
    });
  });

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    await Promise.all(
      beneficiaries
        .map((beneficiary) => ({
          beneficiary_id: beneficiary?.beneficiary?.id,
          trade_ref: existingTrade.reference,
          client_ref: quote.client_ref,
          currency: quote.currency_buy || quote.currency_sell,
          amount: beneficiary?.amount || 0,
          purpose: reason || beneficiary?.reason,
          payment_reference: beneficiary?.payment_reference?.trim() || "",
        }))
        .map((params) =>
          instructPaymentMutation({
            ...params,
            amount: params.amount.toString(),
          })
        )
    );

    if (Utils.isNotEmpty(query?.instructPaymentTradeID)) {
      return router.push("/");
    }

    onComplete({ quote, trade: existingTrade });
  };

  React.useEffect(() => {
    let errorMessage: string;

    if (paymentError) {
      errorMessage = messages.instructPayment.message;
    }

    if (errorMessage) {
      toast.notify({
        message: errorMessage,
        status: TOAST_STATUSES.CRITICAL,
      });
    } else {
      toast.hideNotify();
    }
  }, [paymentError]);

  return (
    <form onSubmit={submitHandler}>
      <h2 className="text-2xl mb-2 text-teal-700">Confirm and pay</h2>

      <p className="text-lg text-gray-600 mb-14">
        By confirming your payment below you are entering into a legal contract
        as per our terms and conditions.
      </p>

      <FormHeading title="Your trade" />

      <FormLabel
        data-testid="you-send-amount"
        label="I have (sell)"
        value={`${Utils.parseAmountByCurrencyCode(
          existingTrade?.sold_amount,
          existingTrade?.sold_currency
        )} ${existingTrade?.sold_currency}`}
      />

      <FormLabel
        data-testid="they-receive-amount"
        label="I want (buy)"
        value={`${Utils.parseAmountByCurrencyCode(
          existingTrade?.bought_amount,
          existingTrade?.bought_currency
        )} ${existingTrade?.bought_currency}`}
      />

      <FormLabel label="Exchange rate">
        {existingTrade?.quote_rate && !quote?.quote_rate && (
          <div className="flex flex-col items-end">
            <div className="ml-4 text-lg text-gray-600">
              {existingTrade.quote_rate.toFixed(4)}
            </div>
            <div className="ml-4 text-xs text-gray-400">
              {`(${(1 / existingTrade.quote_rate).toFixed(4)})`}
            </div>
          </div>
        )}

        {quote?.quote_rate && (
          <div className="flex flex-col items-end">
            <div className="ml-4 text-lg text-gray-600">
              {quote.quote_rate.toFixed(4)}
            </div>
            <div className="ml-4 text-xs text-gray-400">
              {`(${(1 / quote.quote_rate).toFixed(4)})`}
            </div>
          </div>
        )}
      </FormLabel>

      <FormLabel
        clasName="mb-14"
        label="Value Date"
        data-testid="value-date"
        value={formatValueDate(quote?.value_date)}
      />

      <FormHeading
        title={beneficiaries?.length === 1 ? "Beneficiary" : "Beneficiaries"}
      >
        <span className="text-green-400 cursor-pointer" onClick={stepBack}>
          Change
        </span>
      </FormHeading>

      {beneficiaries &&
        beneficiaries?.map(({ beneficiary, amount }) => {
          return (
            <div
              key={beneficiary.id}
              className="mb-12"
              data-testid="beneficiary-content"
            >
              <FormLabel
                label="Account Name"
                value={beneficiary.account_name}
              />
              <FormLabel
                label={beneficiary?.iban ? "IBAN" : "Account number"}
                value={beneficiary?.iban || beneficiary?.account_number}
              />
              {amount > 0 && (
                <FormLabel
                  data-testid="amount"
                  label="Amount"
                  value={`${Utils.parseAmountByCurrencyCode(
                    amount,
                    beneficiary.currency
                  )} ${beneficiary.currency}`}
                />
              )}
            </div>
          );
        })}

      <div className="flex justify-center">
        <Link
          href="/"
          className="border-2 border-gray-700 rounded py-2.5 px-6 mr-6"
        >
          Cancel
        </Link>
        <Button size={Button.Size.LARGE} loading={instructingPayment}>
          Confirm and pay
        </Button>
      </div>
    </form>
  );
};

export default ConfirmPayForm;
