import React from "react";
import classNames from "classnames";
import { ExclamationIcon, MailIcon } from "@heroicons/react/outline";

export const Status = {
  DEFAULT: "default",
  PRIMARY: "primary",
  POSITIVE: "positive",
  CAUTION: "caution",
  CRITICAL: "critical",
} as const;

// Icons tied to status
type HeroIcon = (props: React.SVGProps<SVGSVGElement>) => JSX.Element;

type Values<T> = T[keyof T];

export interface AlertProps
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  text?: string;
  status?: Values<typeof Status>;
  icon?: HeroIcon;
}

type IAlert<Props> = React.FC<Props> & {
  Status: typeof Status;
};

const Icon = (Component: React.ComponentType) => <Component />;

const Alert: IAlert<AlertProps> = ({
  status = Status.DEFAULT,
  text,
  icon,
  children,
}) => {
  const baseClasses = `rounded-md px-4 py-6 border`;

  const icons = {
    [Status.DEFAULT]: MailIcon,
    [Status.PRIMARY]: MailIcon,
    [Status.POSITIVE]: MailIcon,
    [Status.CAUTION]: ExclamationIcon,
    [Status.CRITICAL]: ExclamationIcon,
  };

  const alertClass = classNames(
    baseClasses,
    `border-theme-color-${status}`,
    `text-theme-color-${status}`,
    `bg-theme-color-${status}-background`
  );

  return (
    <div className={alertClass}>
      <div className="flex gap-5 items-center">
        <div className={`text-theme-color-${status} h-6 w-6 flex-shrink-0`}>
          {(icon && Icon(icon)) || Icon(icons[status])}
        </div>
        {text ? <p className="text-sm text-teal-400">{text}</p> : children}
      </div>
    </div>
  );
};

Alert.Status = Status;

export default Alert;
