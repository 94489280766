import * as React from "react";

export interface PaymentDetailFieldProps {
  name: string;
  value: string;
  subtext?: string;
}

const PaymentDetailField: React.FC<PaymentDetailFieldProps> = ({
  name,
  value,
  subtext,
  ...rest
}) => (
  <>
    <div data-testid="pd-bank-name" className="flex items-start my-4" {...rest}>
      <span className="text-lg flex-1 text-teal-700">{name}</span>
      <span className="flex flex-col text-lg flex-1 text-gray-600">
        {value}
        {subtext && <span className="text-xs text-gray-600">{subtext}</span>}
      </span>
    </div>
  </>
);

export default PaymentDetailField;
