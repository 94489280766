import React from "react";
import { FormProps } from "./types";
import * as yup from "yup";
import { Input } from "@clear-treasury/design-system";
import CoreInput from "../../../../core/input/Input";
import { isIbanCountry, validateIBAN } from "./utils";
import Select from "../../../../core/select/Select";
import countries from "../../../../data/countries.json";
import { Flag } from "@clear-treasury/design-system";

const countriesList = countries // TODO: Move this out
  .filter(({ RiskScore }) => !(RiskScore > 0))
  .map(({ CountryName, ISO2 }) => ({
    value: ISO2,
    label: `${CountryName}`,
    selectedLabel: CountryName,
    icon: <Flag country={ISO2.toLowerCase()} />,
  }));

yup.addMethod(yup.string, "iban", function (message) {
  return this.test("iban", message, function (value) {
    if (!value) {
      return false;
    }
    const code = this.parent.country_code;
    return validateIBAN(code, value);
  });
});

export const Schema = yup.object({
  currency: yup.string().required("Currency is required"),
  country_code: yup.string().required("Country is required"),

  account_name: yup.string().required("Account name is required"),
  account_number: yup.string().when("country_code", {
    is: isIbanCountry,
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required("Account number is required"),
  }),

  ben_address: yup.string().test({
    test(value, context) {
      const postCodeLength = context.parent.ben_address_post_code?.length;

      const addressLength = value?.length ?? 0;
      const addressMaxLength = 105 - (postCodeLength ?? 0);

      if (addressLength <= 0) {
        return context.createError({
          message: "Two address lines must be provided",
        });
      }

      if (addressLength > addressMaxLength) {
        return context.createError({
          message: `Only ${addressMaxLength} characters including spaces can be provided`,
        });
      }

      return true;
    },
  }),
  ben_address_post_code: yup.string().test({
    test(value, context) {
      const postCodeLength = value?.length ?? 0;

      if (postCodeLength <= 0) {
        return context.createError({
          message: "Two address lines must be provided",
        });
      }

      if (value?.length > 16) {
        return context.createError({
          message: "Only 16 characters including spaces can be provided",
        });
      }

      return true;
    },
  }),
  ben_country_code: yup.string().test({
    test(value, context) {
      if (!value || value.length === 0) {
        return context.createError({
          message: "Address country must be provided",
        });
      }
      return true;
    },
  }),
  iban: yup
    .string()
    .when("country_code", {
      is: (value: string) => !isIbanCountry(value),
      then: (schema) => schema.nullable(),
      otherwise: (schema) =>
        schema
          .iban("Not a valid IBAN. Please check the number and try again")
          .required("Please provide a valid IBAN"),
    })
    .transform((value) => value?.toUpperCase()),
  swift: yup
    .string()
    .matches(
      /^[A-Za-z]{4}[A-Za-z0-9]{4}(?:[A-Za-z0-9]{3})?$/,
      "Please provide a valid SWIFT BIC (it must be 8 or 11 characters in length, first 4 characters must be letters)"
    )
    .transform((value) => value?.toUpperCase()),
  alias: yup.string().optional(),
  email: yup.string().email("Must be a valid email address").optional(),
});

export function Form({
  values,
  handleChange,
  setFieldValue,
  errors,
}: FormProps & { setFieldValue: any }): JSX.Element {
  const isIban = isIbanCountry(values.country_code);
  return (
    <>
      <div className="block w-full mb-8">
        <h2 className="text-theme-color-on-surface text-2xl">
          Account details
        </h2>

        <p className="text-l text-gray-500">
          Please provide the details of the beneficiary acocunt
        </p>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="swift"
          label="SWIFT BIC"
          placeholder="Enter SWIFT BIC"
          onChange={handleChange}
          hint="Must be 8 or 11 alphanumeric characters"
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="account_name"
          label="Account name"
          placeholder="Enter the beneficiary's account name"
          hint="This should match the name registered on the account you want to pay"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        {isIban ? (
          <Input
            type="text"
            name="iban"
            label="IBAN"
            placeholder="Enter IBAN"
            helpText="An IBAN is an international bank account number. It includes the account number plus other mandatory information required for international payments. IBAN must be used when sending funds to specific countries"
            onChange={handleChange}
            errors={errors}
          />
        ) : (
          <Input
            type="text"
            name="account_number"
            label="Account number"
            placeholder="Enter the beneficiary's account number"
            onChange={handleChange}
            errors={errors}
          />
        )}
      </div>

      <div className="block w-full mb-8">
        <h2 className="text-theme-color-on-surface text-2xl">
          Additional information
        </h2>
      </div>

      <div className="mb-8">
        <CoreInput
          helpLabel="What is this?"
          helpText="For certain countries a full beneficiary address of the business or person you are instructing a payment to is required."
          label="Beneficiary address"
          name="ben_address"
          placeholder="Enter a building number and street name, and city, province or territory"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="mb-8 mt-8">
          <Input
            type="text"
            name="ben_address_post_code"
            placeholder="Enter a city, province or territory, and postal or territory code"
            onChange={handleChange}
            errors={errors}
          />
        </div>

        <div
          data-testid="beneficiary-destination-country"
          className="mb-8 mt-8"
        >
          <Select
            placeholder="Select a country"
            name="ben_country_code"
            options={countriesList}
            onChange={(e) => {
              setFieldValue("ben_country_code", e.selectedItem.value, false);
            }}
            errors={errors}
          />
        </div>
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="alias"
          label="Beneficiary alias (optional)"
          placeholder="Enter a nickname for the beneficiary"
          hint="This is a reference that we will use to display the beneficiary for you"
          onChange={handleChange}
          errors={errors}
        />
      </div>

      <div className="mb-8">
        <Input
          type="text"
          name="email"
          label="Email address (optional)"
          placeholder="Enter an email address for the beneficiary"
          onChange={handleChange}
          errors={errors}
        />
      </div>
    </>
  );
}
