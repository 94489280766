import React, { FormEvent, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { Button, Flag } from "@clear-treasury/design-system";
import Select from "../../../core/select/Select";
import currencies from "../../../data/currencies.json";
import countries from "../../../data/countries.json";
import formTypes from "./form-types";
import { Errors, FormProps } from "./form-types/types";
import {
  Beneficiary,
  Client,
  CreateBeneficiaryInput,
} from "src/graphql/gql-types";
import { WithRequiredProperty } from "src/lib/UtilityTypes";
import { useApp } from "../../../ctx/AppProvider";
import { RadioButtonBox } from "../../../core/radio-button-box/RadioButtonBox";
import { isIbanCountry } from "./form-types/utils";

export type IAddBeneFormOnDone = WithRequiredProperty<
  Partial<CreateBeneficiaryInput>,
  "country_code" | "account_name"
> & {
  isIfBeneficiary?: boolean;
  id?: Beneficiary["id"];
};

export interface AddBeneficiaryProps {
  currency?: string;
  country?: string; // coutnry code in ISO2 format
  stepBack?: (stepNumber: number) => void;
  // TODO: graphql schema needs to be fixed as there is inconsistency in the types
  // for now we are using any
  onComplete?: (args: { beneficiary: IAddBeneFormOnDone }) => void;
}

const getCurrency = (CurrencyCode: string) => ({
  value: CurrencyCode,
  label: `${CurrencyCode}`,
  selectedLabel: CurrencyCode,
  icon: <Flag country={CurrencyCode.slice(0, -1).toLowerCase()} />,
});

const countriesList = countries
  .filter(({ RiskScore }) => !(RiskScore > 0))
  .map(({ CountryName, ISO2 }) => ({
    value: ISO2,
    label: `${CountryName}`,
    selectedLabel: CountryName,
    icon: <Flag country={ISO2.toLowerCase()} />,
  }));

const getCountry = (ISO2?: string): string | undefined => {
  if (!ISO2) return undefined;

  const country = countriesList.find((c) => c.value === ISO2);

  if (!country) throw new Error(`Country ${ISO2} not found`);

  return country.value;
};

const defaultValues = {
  currency: "GBP",
  country_code: "GB",
};

const AddBeneficiaryForm = ({
  currency: ccy,
  country,
  stepBack,
  onComplete,
}: AddBeneficiaryProps): JSX.Element => {
  const currencyRef = React.useRef<HTMLButtonElement | null>(null);
  const [currency, setCurrency] = React.useState<string>(
    ccy || defaultValues.currency
  );
  const [countryCode, setCountryCode] = React.useState<string>(
    country || defaultValues.country_code
  );
  const [accountType, setAccountType] = React.useState<string>("");
  const [accountTypeIsInvalid, setAccountTypeIsInvalid] =
    React.useState<boolean>();
  const [activeClient] = useApp<Client>((store) => store.activeClient);

  useEffect(() => {
    if (activeClient.is_EEA && accountType !== "") {
      formik.setFieldValue("type", accountType, false);
      setAccountTypeIsInvalid(false);
    }
  }, [accountType]);

  const initialValues = {
    country_code: countryCode,
    email: "",
    alias: "",
    account_name: "",
    bank_name: "",
    ben_address: "",
    ben_address_post_code: "",
    ben_country_code: "",
    currency,
    type: "",
  };

  const [Form, Schema] = getFormComponentAndSchema(currency, countryCode);
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values) => {
      onComplete({ beneficiary: values });
    },
    validateOnChange: false,
  });

  const currencyList =
    activeClient.cty_value === "PRIVATE"
      ? currencies.privateClient.map(({ CurrencyCode }) =>
          getCurrency(CurrencyCode)
        )
      : currencies.corporateClient.map(({ CurrencyCode }) =>
          getCurrency(CurrencyCode)
        );

  const submitHandler = (e: FormEvent) => {
    e.preventDefault();
    if (activeClient.is_EEA) {
      const typeIsValid = yup.string().required().isValidSync(accountType);
      if (!typeIsValid) {
        setAccountTypeIsInvalid(true);
      }
      formik.handleSubmit();
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <form onSubmit={submitHandler}>
      <div
        data-testid="beneficiary-bank-location"
        className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 mb-8"
      >
        <Select
          name="currency"
          ref={currencyRef}
          label="Currency"
          options={ccy ? [getCurrency(ccy)] : currencyList}
          defaultValue={currency || defaultValues.currency}
          onChange={(e) => {
            setCurrency(e.selectedItem.value);
            formik.setFieldValue("currency", e.selectedItem.value, false);
          }}
        />

        <Select
          name="country_code"
          label="Destination country"
          options={countriesList}
          helpLabel="What is this?"
          helpText="The country that the beneficiaries bank or payment service provider is located in"
          onChange={(e) => {
            setCountryCode(e.selectedItem.value);
            formik.setFieldValue("country_code", e.selectedItem.value, false);
          }}
          defaultValue={getCountry(country) || defaultValues.country_code}
        />
      </div>
      {activeClient.is_EEA && (
        <>
          <h2 className="text-theme-color-on-surface text-2xl mb-5">
            Account type
          </h2>
          <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-6 mb-8">
            <RadioButtonBox
              checked={accountType === "individual"}
              label="Individual"
              isInvalid={accountTypeIsInvalid}
              selectHandler={setAccountType}
              onChange={formik.handleChange}
              iconUrl="/next/assets/individual.svg"
            />
            <RadioButtonBox
              checked={accountType === "company"}
              label="Business"
              isInvalid={accountTypeIsInvalid}
              selectHandler={setAccountType}
              onChange={formik.handleChange}
              iconUrl="/next/assets/company.svg"
            />
          </div>
        </>
      )}
      <Form
        values={formik.values}
        handleChange={formik.handleChange}
        setFieldValue={(key, val) => {
          formik.setFieldValue(key, val);
        }}
        errors={transformErrors(formik.errors)}
      />
      <div className="flex justify-between">
        <Button
          onClick={() => stepBack(0)}
          emphasis={Button.Emphasis.TRANSPARENT}
        >
          <ArrowLeftIcon width="16" />
          Back
        </Button>
        <Button typeof="submit" size={Button.Size.LARGE}>
          Continue
        </Button>
      </div>
    </form>
  );
};

export default AddBeneficiaryForm;

function transformErrors(errors: Record<string, string>): Errors {
  return Object.keys(errors).reduce((acc, key) => {
    acc[key] = { message: errors[key] };
    return acc;
  }, {} as Errors);
}

function getFormComponentAndSchema(
  currency: string,
  countryCode: string
): [React.FC<FormProps>, yup.ObjectSchema<any>] {
  if (currency === "GBP" && countryCode === "GB") {
    return [formTypes["GBP/GB"].form, formTypes["GBP/GB"].schema];
  }
  if (["AU", "CA", "IN", "PK", "PL", "CN"].includes(countryCode)) {
    return [formTypes[countryCode].form, formTypes[countryCode].schema];
  }
  if (currency === "EUR" && isIbanCountry(countryCode)) {
    return [formTypes["EUR/EEA"].form, formTypes["EUR/EEA"].schema];
  }
  if (currency === "USD" && countryCode === "US") {
    return [formTypes["USD/US"].form, formTypes["USD/US"].schema];
  }

  return [formTypes["Default"].form, formTypes["Default"].schema];
}
