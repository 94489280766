import ibanCountries from "../../../../data/iban-countries.json";

declare module "yup" {
  interface StringSchema {
    iban(val: string): StringSchema;
  }
}

export function isIbanCountry(country_code: string): boolean {
  const found = ibanCountries[country_code];
  return Boolean(found);
}

export function validateIBAN(country_code: string, value: string): boolean {
  const country = ibanCountries[country_code];
  const iban = value.replace(/ /g, "").toUpperCase();

  if (country.iban_length !== iban.length) {
    return false;
  }

  const countryCode = iban.slice(0, 2);
  if (countryCode !== country_code.toUpperCase()) {
    return false;
  }
  const checkDigits = iban.slice(2, 4);
  const bban = iban.slice(4) + countryCode + checkDigits;
  const num = bban
    .split("")
    .map((char) => {
      const code = char.charCodeAt(0);
      return code >= "A".charCodeAt(0) && code <= "Z".charCodeAt(0)
        ? code - "A".charCodeAt(0) + 10
        : char;
    })
    .join("");
  let temp = "";
  for (let i = 0; i < num.length; i += 7) {
    temp = (parseInt(temp + num.slice(i, i + 7), 10) % 97) + "";
  }
  return parseInt(temp, 10) === 1;
}
