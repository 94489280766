import * as React from "react";
import Link from "next/link";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Alert, Button } from "@clear-treasury/design-system";
import { IBeneficiaryFormData } from "../beneficiary-form/BeneficiaryForm";
import PaymentDetailField from "./ui/PaymentDetailField";
import { Client } from "src/graphql/gql-types";

export interface PaymentDetailsProps {
  client: Client;
  amount: number;
  received: number;
  currency: string;
  trade_ref: string;
  beneficiaries: IBeneficiaryFormData[];
  account_name: string;
  bank_name: string;
  account_number?: string;
  iban?: string;
  sort_code?: string;
  swift_code?: string;
}

const BankDetails = ({
  bank_name,
  account_name,
  account_number,
  iban,
  sort_code,
  swift_code,
}) => {
  return (
    <>
      {bank_name && (
        <PaymentDetailField
          data-testid="pd-bank-name"
          name="Bank name"
          value={bank_name}
        />
      )}

      {account_name && (
        <PaymentDetailField
          data-testid="pd-acc-name"
          name="Account name"
          value={account_name}
        />
      )}

      {account_number && (
        <PaymentDetailField
          data-testid="pd-acc-number"
          name="Account number"
          value={account_number}
        />
      )}

      {sort_code && (
        <PaymentDetailField
          data-testid="pd-sort-code"
          name="Sort code"
          value={sort_code}
        />
      )}

      {swift_code && (
        <PaymentDetailField
          data-testid="pd-swift-bic"
          name="SWIFT BIC"
          value={swift_code}
        />
      )}

      {iban && (
        <PaymentDetailField data-testid="pd-iban" name="IBAN" value={iban} />
      )}
    </>
  );
};

const PaymentDetails = ({
  client,
  trade_ref,
  amount,
  received,
  currency,
  account_name,
  bank_name,
  account_number,
  iban,
  sort_code,
  swift_code,
}: PaymentDetailsProps): JSX.Element => {
  return (
    <>
      <h2 className="text-2xl mb-2 text-black">Payment details</h2>

      <p
        className={`text-lg text-gray-500 mt-0 ${
          client.is_EEA ? "mb-4" : "mb-8"
        }`}
      >
        {amount === received
          ? "You have funded this transfer using your balance so there's no need to make a payment."
          : "Fund your transfer by making a payment to Clear Treasury using the following details"}
      </p>

      {client.is_EEA && (
        <div className="w-full justify-start items-center gap-1 inline-flex mb-2">
          <InformationCircleIcon className="w-5 h-5" />
          <div className="grow shrink basis-0 text-neutral-600 text-sm font-normal font-['Sofia Pro'] leading-tight">
            These details are for SEPA payments only. If you need to make an
            international payment via SWIFT please contact your dealer.
          </div>
        </div>
      )}

      <div className="mt-2 mb-8">
        <PaymentDetailField
          data-testid="pd-amount"
          name="Amount due"
          value={`${(amount - received).toFixed(2)} ${currency}`}
          subtext={
            received > 0 &&
            received < amount &&
            `(${received.toFixed(2)} ${currency} balance used)`
          }
        />

        {amount !== received && (
          <BankDetails
            bank_name={bank_name}
            account_name={account_name}
            account_number={account_number}
            iban={iban}
            sort_code={sort_code}
            swift_code={swift_code}
          />
        )}

        {trade_ref && (
          <PaymentDetailField
            data-testid="pd-trade-ref"
            name={amount !== received ? "Payment reference" : "Trade reference"}
            value={trade_ref}
          />
        )}
      </div>

      <div
        data-testid="info-box"
        className="pb-8 border-b border-gray-200 mb-14"
      >
        {client?.needs_approval ? (
          <Alert
            status={Alert.Status.PRIMARY}
            text={"An email requesting approval for the payment has been sent."}
          />
        ) : (
          <Alert
            status={Alert.Status.PRIMARY}
            text={
              "An email confirming the above details and outlining next steps has also been sent."
            }
          />
        )}
      </div>

      <div className="flex justify-end">
        <Button as={Link} href="/" size={Button.Size.LARGE}>
          <span>Continue</span>
        </Button>
      </div>
    </>
  );
};

export default PaymentDetails;
