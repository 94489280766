import { Dispatch, SetStateAction } from "react";
import { Errors } from "src/core/input/Input";

interface RadioButtonBoxProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  iconUrl?: string;
  error?: Errors;
  errorKey?: string;
  selectHandler?: Dispatch<SetStateAction<string>>;
  isInvalid?: boolean;
}

const entityTypeMappings = {
  Business: "company",
  Individual: "individual",
};

export const RadioButtonBox = ({
  checked,
  label,
  iconUrl,
  error = {},
  errorKey,
  selectHandler,
  isInvalid,
}: RadioButtonBoxProps): JSX.Element => {
  return (
    <div
      className={`flex items-center place-content-between px-5 py-4 shadow-md w-full relative border cursor-pointer ${
        checked && "bg-gray-100"
      } ${
        error && error[errorKey]
          ? "border-theme-color-error"
          : isInvalid
          ? "border-theme-color-error"
          : ""
      } `}
      onClick={() => selectHandler(entityTypeMappings[label])}
    >
      <input
        className="cursor-pointer"
        type="radio"
        name={label}
        value={label}
        checked={checked}
        readOnly
      />
      <label className="pr-40 pl-3 cursor-pointer" htmlFor="individual">
        {label}
      </label>
      {iconUrl && (
        <div className="w-12">
          <img
            className="fill-current mx-auto"
            src={iconUrl}
            alt="Entity Type"
          />
        </div>
      )}
    </div>
  );
};
