import * as React from "react";
import { SelectChangeHandler } from "@clear-treasury/design-system/dist/components/select/Select";
import MaskedInput from "../reactTextMask/reactTextMask";
import { createNumberMask } from "text-mask-addons";
import classNames from "classnames";
import ResponsiveFlag from "../ResponsiveFlag/ResponsiveFlag";
import Select from "../Select/Select";

type Errors = { [name: string]: Error };

export type MoneyInputProps = {
  name: string;
  label: string;
  currencies: string[];
  errors?: Errors;
  amount: string;
  currency: string;
  defaultAmount?: string;
  defaultCurrency?: string;
  onfocus?: () => void;
  onAmountChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCurrencyChange?: SelectChangeHandler;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  disabled?: boolean;
  disableDropdown?: boolean;
  shadedOut?: boolean;
};

const MoneyInput: React.FC<MoneyInputProps> = ({
  name,
  label,
  currencies,
  errors,
  disabled,
  amount,
  currency,
  defaultCurrency,
  onAmountChange,
  onCurrencyChange,
  shadedOut = false,
  disableDropdown = false,
  onfocus = () => {},
}) => {
  const options = currencies.map((currency) => ({
    value: currency,
    label: `${currency}`,
    selectedLabel: currency,
    icon: (
      <ResponsiveFlag size="sm" country={currency.slice(0, -1).toLowerCase()} />
    ),
  }));
  const inputClasses = classNames(
    "form-input",
    "border border-gray-300",
    `${shadedOut ? "bg-gray-200" : "bg-white"}`,
    "mb-2 p-3",
    "w-full",
    "focus:ring-teal-100 focus:border-teal-300",
    "disabled:cursor-not-allowed",
    "placeholder-gray-400",
    "rounded-l",
    {
      "border-theme-color-error": errors && errors[name],
      "text-gray-400": disabled,
    }
  );

  return (
    <div>
      <label
        htmlFor="money-input-label"
        className="text-base text-gray-700 inline-block mb-2"
      >
        {label}
      </label>

      <div onClick={onfocus} className="flex items-stretch bg-stone-500">
        <MaskedInput
          id="money-input-label"
          name={name}
          type="text"
          disabled={disabled}
          className={inputClasses}
          placeholder={"Enter an amount"}
          mask={createNumberMask({
            prefix: "",
            suffix: "",
            allowDecimal: true,
          })}
          guide={false}
          value={amount}
          onChange={onAmountChange}
        />
        <div className="w-60">
          <label id={`${name}_currency-label`} className="sr-only">
            {/* This stops the label being rendered as two separate strings */}
            {`${name} currency`}
          </label>

          <Select
            inverted
            disabled={disableDropdown}
            paired="right"
            name={`${name}_currency`}
            options={options}
            onChange={onCurrencyChange}
            defaultValue={defaultCurrency}
            setItem={options.find(({ value }) => value === currency)}
          />
        </div>
      </div>
    </div>
    // </FormField>
  );
};

export default MoneyInput;
