import * as React from "react";
import { FC } from "react";
import Utils from "../../core/Utils";

export interface BalanceLabelProps {
  amount: number;
  currency: string;

  hasError?: boolean;
  hasOnly?: boolean;
  onClick?: () => void;
}

const BalanceLabel: FC<BalanceLabelProps> = ({
  amount,
  currency,
  hasOnly,
  onClick,
  hasError = false,
}) => {
  return (
    <div
      data-testid="debit-balance"
      className={`${
        hasError && "text-theme-color-error"
      } text-sm text-gray-600`}
    >
      {`You ${hasOnly ? "only" : ""} have `}
      <span
        data-testid={"label-amount"}
        onClick={onClick}
        className={`${
          onClick ? "cursor-pointer" : ""
        } text-green-600 font-normal`}
      >
        {Utils.parseAmountByCurrencyCode(amount, currency)} {currency}
      </span>{" "}
      available in your balance
    </div>
  );
};

export default BalanceLabel;
