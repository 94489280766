import * as React from "react";
import { FC } from "react";

export interface FormHeadingProps {
  title: string;
  children?: React.ReactNode;
}

const FormHeading: FC<FormHeadingProps> = ({ title, children }) => {
  return (
    <div className="flex justify-between mb-4 border-b border-gray-200 pb-4">
      <h3 className="text-xl text-gray-700">{title}</h3>
      {children}
    </div>
  );
};

export default FormHeading;
